import React from 'react';
import { useTranslation } from 'react-i18next';

import Hero from '@shared/hero/hero';

import { Graphql } from '@models/graphql';

import './styles.scss';
import NewsletterForm from './form';

export default function NewsletterUnsubscribe(): React.ReactElement {
  const { t } = useTranslation();

  const heroData = {
    title: t('Newsletter'),
    subtitle: t('Newsletter hero subtitle'),
    relationships: {
      image: {
        name: 'bg-why-rootstack.png',
      }
    }
  } as Graphql;

  return (
    <>
      <Hero node={heroData} />
      <div className="newsletter-container my-5 container">
        <p className="title text-marine h1 text-center">
          {t('Newsletter Unsubscribe')}
        </p>
        <NewsletterForm />
      </div>
    </>
  );
}
